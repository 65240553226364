import api from '@/api/user';

const store = {
    state: {
        users: [],
        loaded: false
    },
    getters: {
        users: state => {
            return state.users;
        },
    },
    mutations: {
        setUsers(state, data) {
            state.users = data;
            state.loaded = true;
        },
        removeUsers(state, data) {
            state.users = state.users.filter( (user) => !data.includes( user.code) );
        },
        createUser(state, data) {
            state.users.push(data);
        },
        updateUser(state, data) {
            state.users = state.users.map( (user) => {
                if (user.code == data.code){
                    return data
                }
                return user;
            } );
        }
    },
    actions: {
        loadUsers(context) {
            if (context.state.loaded)
                return
            api.load((data) => {
                context.commit('setUsers', data);
            })
            window.Echo.private(`Store.Notify.all`)
                .listen('.users.deleted', (e) => {
                    context.commit('removeUsers', e.data);
                }).listen('.users.updated', (e) => {
                    context.commit('updateUser', e);
                }).listen('.users.created', (e) => {
                    context.commit('createUser', e);
                })
        }
    }
}

export default store
