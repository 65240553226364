import axios from "axios";

export default {
    loadDataController: null,
    load(callback){
        if (this.loadDataController != null)
            this.loadDataController.abort()
        this.loadDataController = new AbortController()

        axios.get(route("getUsers"), {
            signal: this.loadDataController.signal,
        }).then((response) => {
            callback( response.data)
        });
    },
}
