import HeaderMenu from './HeaderMenuState';
import Loadin from './LoadingState';
import Addresses from './AdressesState';
import Organizations from './OrganizationState';
import Users from './UsersState';
import Vuex from 'vuex'
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Addresses,
        Organizations,
        Users,
        HeaderMenu,
        Loadin
    }
});
