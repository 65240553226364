import api from '@/api/organization';

const store = {
    state: {
        organizations: [],
        loaded: false
    },
    getters: {
        organizations: state => {
            return state.organizations;
        },
    },
    mutations: {
        setOrganizations(state, data) {
            state.organizations = data;
            state.loaded = true;
        },
        removeOrganizations(state, data) {
            state.organizations = state.organizations.filter( (organization) => !data.includes( organization.code) );
        },
        updateOrganization(state, data) {
            state.organizations = state.organizations.map( (organization) => {
                if (organization.code == data.code){
                    return data
                }
                return organization;
            } );
        },
        createOrganization(state, data) {
            state.organizations.push(data);
        }
    },
    actions: {
        loadOrganizations(context) {
            if (context.state.loaded)
                return
            api.loadOrganizationList((data) => {
                context.commit('setOrganizations', data);
            })

            window.Echo.private(`Store.Notify.all`)
                .listen('.organization.deleted', (e) => {
                    context.commit('removeOrganizations', e.data);
                }).listen('.organization.updated', (e) => {
                context.commit('updateOrganization', e);
            }).listen('.organization.created', (e) => {
                context.commit('createOrganization', e);
            })
        }
    }
}

export default store
