import axios from "axios";

export default {
    loadOrganizationDataController: null,
    loadOrganizationData(id, callback){
        if (this.loadOrganizationDataController != null)
            this.loadOrganizationDataController.abort()
        this.loadOrganizationDataController = new AbortController()

        axios.get(route("api.organizations.get", id), {
            signal: this.loadOrganizationDataController.signal,
        }).then((response) => {
            callback( response.data)
        });
    },
    loadOrganizationList(callback){
        if (this.loadOrganizationDataController != null)
            this.loadOrganizationDataController.abort()
        this.loadOrganizationDataController = new AbortController()

        axios.get(route("getAllOrganizations"), {
            signal: this.loadOrganizationDataController.signal,
        }).then((response) => {
            callback( response.data)
        });
    },
    addOrganization(org){

    },
    updateOrganization(org){

    },
    deleteOrganization(id){

    }
}
