import api from '@/api/address';

const store = {
    state: {
        addresses: [],
        loaded: false
    },
    getters: {
        addresses: state => {
            return state.addresses;
        },
    },
    mutations: {
        set(state, data) {
            state.addresses = data;
            state.loaded = true;
        },
        removeAddresses(state, data) {
            state.addresses = state.addresses.filter( (address) => !data.includes( address.code) );
        },
        updateAddress(state, data) {
            state.addresses = state.addresses.map( (address) => {
                if (address.code == data.code){
                    return data
                }
                return address;
            } );
        },
        createAddress(state, data) {
            state.addresses.push(data);
        }
    },
    actions: {
        loadAddresses(context) {
            if (context.state.loaded)
                return
            api.load((data) => {
                context.commit('set', data);
            })

            window.Echo.private(`Store.Notify.all`)
                .listen('.house.deleted', (e) => {
                    context.commit('removeAddresses', e.data);
                }).listen('.house.updated', (e) => {
                context.commit('updateAddress', e);
            }).listen('.house.created', (e) => {
                context.commit('createAddress', e);
            })
        }
    }
}

export default store
